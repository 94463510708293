var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-width",staticStyle:{"min-height":"600px","max-width":"1600px","padding":"0 50px"}},[_c('CustomForm',{ref:"CustomForm",attrs:{"method":"getAllAnnouncement","params":{ receiver: 1 },"formItems":[
      { type: 'input', label: '公告搜索', key: 'content', note: '按关键字搜索' },
      { type: 'select', label: '发布部门', key: 'sender', default: 0, option: [
        { value: 0, label: '全部' },
        { value: 1, label: '供应链平台' },
        { value: 2, label: '其他' }
      ] },
      { type: 'select', label: '是否紧急', key: 'status', note: '默认显示全部', option: [
        { value: 1, label: '否' },
        { value: 2, label: '是' }
      ] },
      { type: 'time', label: '发布时间', key: 'create_time' } ]}},[_c('a-table',{staticStyle:{"margin":"20px"},attrs:{"columns":[
          { title: '发布时间', dataIndex: 'create_time' },
          { title: '主题', dataIndex: 'title' },
          { title: '发布部门', dataIndex: 'sender', customRender: function (text) { return (text === 1 ? '供应链平台' : '其它'); } },
          { title: '是否紧急', dataIndex: 'status', customRender: function (text) { return text === 2 ? ' 紧急' : ''; } },
          { title: '紧急截止时间', dataIndex: 'urgent_endtime', customRender: function (text, record) { return (record.status === 2 ? text : ''); } },
          { title: '操作', key: 'action', scopedSlots: { customRender: 'action' } }
        ],"row-key":function (record) { return record.id_platform_announcement; },"data-source":_vm.data,"loading":_vm.spinning,"pagination":false},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a-button',{staticStyle:{"padding":"0"},attrs:{"type":"link"},on:{"click":function($event){return _vm.$info({
            title: record.title,
            content: record.content,
          })}}},[_vm._v("详情")])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }