标题：公告页面
作者：张芮
时间：2021.02.03
<template>
  <div class="max-width" style="min-height: 600px;max-width:1600px;padding: 0 50px">
    <CustomForm
      method="getAllAnnouncement"
      ref="CustomForm"
      :params="{ receiver: 1 }"
      :formItems="[
        { type: 'input', label: '公告搜索', key: 'content', note: '按关键字搜索' },
        { type: 'select', label: '发布部门', key: 'sender', default: 0, option: [
          { value: 0, label: '全部' },
          { value: 1, label: '供应链平台' },
          { value: 2, label: '其他' }
        ] },
        { type: 'select', label: '是否紧急', key: 'status', note: '默认显示全部', option: [
          { value: 1, label: '否' },
          { value: 2, label: '是' }
        ] },
        { type: 'time', label: '发布时间', key: 'create_time' },
      ]"
    >
      <a-table
        :columns="[
            { title: '发布时间', dataIndex: 'create_time' },
            { title: '主题', dataIndex: 'title' },
            { title: '发布部门', dataIndex: 'sender', customRender: text => (text === 1 ? '供应链平台' : '其它') },
            { title: '是否紧急', dataIndex: 'status', customRender: text => text === 2 ? ' 紧急' : '' },
            { title: '紧急截止时间', dataIndex: 'urgent_endtime', customRender: (text, record) => (record.status === 2 ? text : '') },
            { title: '操作', key: 'action', scopedSlots: { customRender: 'action' } }
          ]"
        :row-key="(record) => record.id_platform_announcement"
        :data-source="data"
        :loading="spinning"
        :pagination="false"
        style="margin: 20px"
      >
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            style="padding: 0"
            @click="$info({
              title: record.title,
              content: record.content,
            })"
          >详情</a-button>
        </span>
      </a-table>
    </CustomForm>
  </div>
</template>

<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      spinning: false,
      data: [],
      receivers: ['全部', '加盟商', '供应商', '施工方']
    }
  },

  components: {
    CustomForm
  }
}
</script>
